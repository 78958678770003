<template>
  <ComplianceSubcategoryGrid />
</template>

<script>
import ComplianceSubcategoryGrid from "@/components/ComplianceSubcategoryGrid.vue";

export default {
  name: "ComplianceSubcategoryExternal",
  components: {
    ComplianceSubcategoryGrid: ComplianceSubcategoryGrid,
  },
  created() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>
